import { gql } from '@apollo/client';

export const CURRENT_WAREHOUSE_PRODUCTS_QUERY = gql`
  query CurrentWarehouseProducts($distributorId: Int, $warehouseId: Int, $productId: Int) {
    rp {
      currentWarehouseProducts(
        distributorId: $distributorId
        warehouseId: $warehouseId
        productId: $productId
      ) {
        id
        qty
        availableQty
        baseUnitId
        lotNbr
        startDate
        endDate
        product {
          cd
          id
          name
          purchaseUnitId
          productMeasureUnits {
            id
            fromUnitId
            toUnitId
            rate
            saleable
          }
        }
        warehouse {
          id
          name
          distributor {
            cd
            id
            name
          }
        }
      }
    }
  }
`;
