import { ApolloError } from '@apollo/client';
import CIcon from '@coreui/icons-react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CForm,
} from '@coreui/react';
import React, { FormEventHandler, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { PayloadMessage } from '../queries';
import { theme } from '../types/theme';
import { DataMessage } from './DataMessage';
import { Submitting } from './Submitting';

type FormAction = {
  text: string;
  action: () => void;
};
export const customStyles = {
  rows: {
    style: {
      minHeight: '',
      paddingTop: '10px',
      paddingBottom: '10px',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      fontWeight: 'bold',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
};
export const PageForm = ({
  title,
  listStack,
  primaryAction,
  actions,
  loading,
  isReadOnly,
  submitting,
  loadError,
  submitError,
  submitMessage,
  saveText = 'Xác nhận',
  backUrl,
  isValidated = false,
  children,
  footerChild,
  onRefresh,
  onHandleSubmit,
  onSubmit,
}: {
  title: string;
  listStack?: string[];
  primaryAction?: FormAction;
  actions?: FormAction[];
  loading?: boolean;
  isReadOnly?: boolean;
  submitting?: boolean;
  loadError?: ApolloError;
  submitError?: ApolloError;
  submitMessage?: PayloadMessage | null;
  saveText?: string;
  backUrl?: string;
  isValidated?: boolean;
  children?: React.ReactChild | React.ReactChild[];
  footerChild?: React.ReactChild | React.ReactChild[];
  onRefresh?: () => void;
  onHandleSubmit?: FormEventHandler<HTMLFormElement>;
  onSubmit?: () => void;
}) => {
  const [validated, setValidated] = useState(false);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (!form.checkValidity()) {
      setValidated(true);
      return;
    }
    setValidated(true);

    onSubmit?.();
  };

  return (
    <div>
      <div
        className="mb-2"
        style={{
          paddingTop: 5,
          backgroundColor: theme.colors.light2,
        }}
      >
        {listStack && (
          <div style={{ display: 'flex' }}>
            {listStack.map((i, idx) => {
              return (
                <div
                  key={idx}
                  style={{
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <div
                    className="row"
                    style={{
                      alignItems: 'center',
                      alignContent: 'center',
                      justifyContent: 'center',
                      paddingRight: 10,
                    }}
                  >
                    <div className="col">
                      <span
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color:
                            idx + 1 === listStack.length
                              ? theme.colors.lightGrey2
                              : theme.colors.normalText,
                        }}
                      >
                        {i}
                      </span>
                      {idx + 1 < listStack.length && (
                        <span style={{ fontSize: 16, fontWeight: 500, marginLeft: 10 }}>{'>'}</span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <CCard className="position-relative p-3 mb-3">
        <CForm
          noValidate={true}
          validated={validated || isValidated}
          onSubmit={onHandleSubmit || handleSubmit}
        >
          <div className="d-flex justify-content-between align-items-center">
            <span style={{ flex: 1, fontWeight: 600, fontSize: 24 }}>{title}</span>
            <div>
              {onRefresh && (
                <CButton
                  style={{
                    backgroundColor: theme.colors.primary,
                    borderColor: theme.colors.primary,
                  }}
                  size="sm"
                  variant="ghost"
                  className="mx-2"
                  onClick={() => onRefresh()}
                >
                  <CIcon name="cil-reload" size="sm" />
                </CButton>
              )}
              {primaryAction && (
                <CButton
                  style={{
                    backgroundColor: theme.colors.primary,
                    borderColor: theme.colors.primary,
                  }}
                  size="sm"
                  onClick={() => primaryAction.action()}
                >
                  {primaryAction.text}
                </CButton>
              )}
              {actions && actions.length > 0 && (
                <ul className="d-inline-block list-inline mb-0">
                  <CDropdown variant="nav-item">
                    <CDropdownToggle caret={false} className="px-3 py-1">
                      <CButton
                        style={{
                          backgroundColor: theme.colors.light3,
                          borderColor: theme.colors.lightText,
                          color: theme.colors.iconGrey,
                        }}
                        size="sm"
                        onClick={() => {
                          /**/
                        }}
                      >
                        <CIcon name="cil-options" />
                      </CButton>
                    </CDropdownToggle>
                    <CDropdownMenu {...({} as any)}>
                      {actions.map((a, idx) => (
                        <CDropdownItem onClick={() => a.action()} key={idx}>
                          {a.text}
                        </CDropdownItem>
                      ))}
                    </CDropdownMenu>
                  </CDropdown>
                </ul>
              )}
            </div>
          </div>
          <CCard style={{ backgroundColor: theme.colors.lightBackground }} className="mt-2">
            <CCardBody>
              {loading ? (
                <div className="d-block  w-100 my-3">
                  <Skeleton count={7} />
                </div>
              ) : loadError ? (
                <DataMessage error={loadError} />
              ) : (
                children
              )}
            </CCardBody>
          </CCard>
          {!loading && !loadError && (
            <CCardFooter
              style={{ margin: '-1rem', marginTop: '1rem', backgroundColor: theme.colors.white }}
              className="py-3"
            >
              <DataMessage error={submitError} message={submitMessage} />
              {saveText !== '' && !isReadOnly && (
                <CButton
                  style={{
                    backgroundColor: theme.colors.primary,
                    borderColor: theme.colors.primary,
                  }}
                  type="submit"
                >
                  {saveText}
                </CButton>
              )}
              {!!backUrl && (
                <Link
                  style={{ backgroundColor: theme.colors.light3 }}
                  className="btn btn-secondary ms-2"
                  to={backUrl}
                >
                  Trở lại
                </Link>
              )}
            </CCardFooter>
          )}
          {submitting && <Submitting />}
        </CForm>
      </CCard>
    </div>
  );
};
