import { CCol, CRow } from '@coreui/react';
import React from 'react';
import DataTable, { IDataTableProps } from 'react-data-table-component';
import Skeleton from 'react-loading-skeleton';
import { customStyles } from '.';
import * as iconsPNG from '../assets/iconPNG/index';
import { Constants } from '../constants';

export type TableProps<T> = {
  page?: number;
  pageSize?: number;
  pageSizes?: number[];
  data?: T[];
  total?: number;
  loading: boolean;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
} & IDataTableProps<T>;

export const Table = <T extends unknown>({
  page = 1,
  pageSize = Constants.pageSize,
  pageSizes = Constants.pageSizes,
  data,
  total,
  columns,
  loading,
  onPageChange,
  onPageSizeChange,
  ...restProps
}: TableProps<T>) => {
  return (
    <>
      <DataTable
        noHeader={true}
        customStyles={customStyles}
        columns={columns}
        striped={true}
        highlightOnHover={true}
        style={{ minHeight: data.length === 0 && !loading ? '10vh' : '40vh' }}
        data={data || []}
        progressPending={loading}
        noDataComponent={<></>}
        progressComponent={
          <div className="d-block  w-100 my-3">
            <Skeleton count={7} />
          </div>
        }
        persistTableHead={true}
        pagination={true}
        paginationServer={!!onPageChange}
        paginationTotalRows={total}
        paginationDefaultPage={page}
        paginationPerPage={pageSize}
        paginationRowsPerPageOptions={pageSizes}
        onChangePage={(p, _total) => onPageChange?.(p)}
        onChangeRowsPerPage={(r) => onPageSizeChange?.(r)}
        {...restProps}
      />
      {data.length === 0 && !loading && (
        <CCol style={{ paddingTop: '20vh', paddingBottom: '20vh' }}>
          <CRow>
            <CCol
              style={{
                flex: 1,
                textAlign: 'center',
              }}
            >
              <img src={iconsPNG.icEmpty} width={80} height={80} sizes={'contain'} alt="" />
            </CCol>
          </CRow>
          <CRow>
            <CCol
              className="ms-3"
              style={{
                flex: 1,
                textAlign: 'center',
              }}
            >
              <span style={{ textAlign: 'center' }}>Không có dữ liệu</span>
            </CCol>
          </CRow>
        </CCol>
      )}
    </>
  );
};
